
import { createI18n } from "vue-i18n";
import Cookies from 'js-cookie'
import en_us from "./en-US";
import zh_cn from "./zh-CN";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

/**
 * 索引语言字段 src/i18n/lang 为框架语言 src/i18n/page 为业务界面语言
 */
export const supportLangs = {
  "zhCN": {
    langName: "简体中文",
    ...zhCN,
    ...zh_cn

  },
  "enUS": {
    langName: "English",
    ...enUS,
    ...en_us
  }
};

/**
 * 取语言名称
 * @param lang
 * @returns
 */
export const getLangName = (lang: string): string => {
  return supportLangs[lang]?.langName;
};



export const initI18n = createI18n({
  locale: Cookies.get('language') || 'enUS',
  fallbackLocale: "enUS",
  legacy: false,
  messages: supportLangs
});

export const $t = (initI18n.global as any).t;