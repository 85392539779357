/*
 * @Author: e “v_caoky@digitalgd.com.cn
 * @Date: 2023-08-14 12:07:32
 * @LastEditors: e “v_caoky@digitalgd.com.cn
 * @LastEditTime: 2023-09-06 19:44:02
 * @FilePath: \VaultekSafe-Admin\src\main.ts
 * @Description: 
 */
/*
 * @Author: e “v_caoky@digitalgd.com.cn
 * @Date: 2023-08-14 12:07:32
 * @LastEditors: e “v_caoky@digitalgd.com.cn
 * @LastEditTime: 2023-09-06 18:24:44
 * @FilePath: \VaultekSafe-Admin\src\main.ts
 * @Description: 
 */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import components from './components'
import router from './router'
import { initI18n } from './i18n'
import './style.less'

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

const app = createApp(App)

app.use(store)
  .use(router)
  .use(components)
  //@ts-ignore
  .use(initI18n)
  .mount('#app')
