<!--
 * @Author: e “v_caoky@digitalgd.com.cn
 * @Date: 2023-08-19 08:39:02
 * @LastEditors: e “v_caoky@digitalgd.com.cn
 * @LastEditTime: 2023-09-06 19:57:31
 * @FilePath: \VaultekSafe-Admin\src\App.vue
 * @Description: 
-->
<template>
  <ConfigProvider :locale="localeJson">
<!--    <router-view v-slot="{ Component }">-->
<!--      <keep-alive>-->
<!--        <component :is="Component" />-->
<!--      </keep-alive>-->
<!--    </router-view>-->
    <router-view />
  </ConfigProvider>
</template>


<script setup lang="ts">

import { ConfigProvider } from 'jetlinkscky-ui-components'
import { storeToRefs } from 'pinia';
import { useSystem } from './store/system';
import DefaultSetting from '../config/config'
import { useI18n } from 'vue-i18n'
import { supportLangs } from '@/i18n'


const system = useSystem();
const {configInfo} = storeToRefs(system);
const { locale } = useI18n()
const localeJson = ref(supportLangs[locale.value])


const setLang = (vl: string) => {
  localeJson.value = supportLangs[vl]
}

watch(() => locale.value, setLang)
watchEffect(() => {
  const ico: any = document.querySelector('link[rel="icon"]');
  if(ico !== null){
    ico.href = configInfo.value?.front?.ico || DefaultSetting?.logo
  }
})

</script>

<style scoped>
</style>
