<template>
  <j-result status="404" title="404">
  </j-result>
</template>

<script>
export default {
  name: '404.vue'
}
</script>

<style scoped>

</style>