<!-- $t('GeoComponent.zuoBDSQZJ') -->
<template>
    <div class="page-container">
        <j-input allowClear v-model:value="inputPoint">
            <template #addonAfter>
                <environment-outlined @click="modalVis = true" />
            </template>
        </j-input>
        <j-modal
            :title="$t('GeoComponent.deLWZ')"
            :ok-text="$t('GeoComponent.queR')"
            :cancel-text="$t('GeoComponent.quX')"
            v-model:visible="modalVis"
            width="700px"
            @cancel="modalVis = false"
            @ok="handleModalSubmit"
            destroyOnClose
        >
            <div style="width: 100%; height: 400px">
                <el-amap
                    :center="center"
                    :zoom="zoom"
                    @init="initMap"
                    @click="clickMap"
                >
                    <el-amap-search-box visible @select="selectPoi" />
                    <el-amap-marker :position="position" />
                </el-amap>
                {{ mapPoint }}
            </div>
        </j-modal>
    </div>
</template>

<script setup lang="ts">
import { initAMapApiLoader } from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css';
import { EnvironmentOutlined } from '@ant-design/icons-vue';

initAMapApiLoader({
    // key: '95fa72137f4263f8e64ae01f766ad09c',
    key: 'a0415acfc35af15f10221bfa5a6850b4',
    securityJsCode: 'cae6108ec3dd222f946d1a7237c78be0',
});

interface EmitProps {
    (e: 'update:point', data: string): void;
    (e: 'change', data: string): void;
}
const props = defineProps({
    point: { type: [Number, String], default: '' },
});
const emit = defineEmits<EmitProps>();

// $t('GeoComponent.shouDSRDZBD')($t('GeoComponent.jingWZZFC'))
const inputPoint = computed({
    get: () => {
        return props.point;
    },
    set: (val: any) => {
        mapPoint.value = val;
        emit('update:point', val);
        emit('change', val);
    },
});

// $t('GeoComponent.deTTC')
const modalVis = ref<boolean>(false);
const handleModalSubmit = () => {
    inputPoint.value = mapPoint.value;
    modalVis.value = false;
};

// $t('GeoComponent.deTSQDZBD')($t('GeoComponent.jingWZZFC'))
const mapPoint = ref('');

const zoom = ref(12);
const center = ref([106.55, 29.56]);
let map: any = null;

// $t('GeoComponent.deTJWZ')
const position = ref<number[] | string[]>([]);

/**
 * $t('GeoComponent.deTCSH')
 * @param e
 */
const initMap = (e: any) => {
    map = e;

    const pointStr = mapPoint.value as string;
    position.value = pointStr ? pointStr.split(',') : center.value;
};

/**
 * $t('GeoComponent.deTDJ')
 * @param e
 */
const clickMap = (e: any) => {
    mapPoint.value = `${e.lnglat.lng},${e.lnglat.lat}`;
    position.value = [e.lnglat.lng, e.lnglat.lat];
};

/**
 * $t('GeoComponent.xuanZXSJL')
 * @param e
 */
const selectPoi = (e: any) => {
    const selectPoint = [e.poi.location.lng, e.poi.location.lat];
    mapPoint.value = selectPoint.join(',');
    map.setCenter(selectPoint);
};
</script>

<style lang="less" scoped></style>
